/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { rhythm } from '@utils/typography'
import { useLockBodyScroll } from 'react-use'
import { motion, AnimatePresence } from 'framer-motion'

const modalVariants = {
  open: {
    opacity: 1,
    height: `${100}%`,
    transition: {
      duration: 0.6,
      ease: [0.43, 0.13, 0.23, 0.96],
      delayChildren: 0.5,
    },
  },
  closed: {
    opacity: 0,
    height: `${0}%`,
    transition: {
      duration: 0.6,
      ease: [0.43, 0.13, 0.23, 0.96],
      delay: 0.35,
      when: 'afterChildren',
    },
  },
}
const modalChildVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.25,
    },
  },
  closed: {
    opacity: 0,
    y: -100,
    transition: {
      delay: 0,
      duration: 0.35,
    },
  },
}
const closeVariants = {
  open: {
    opacity: 0.75,
    transition: {
      duration: 0.6,
      ease: [0.6, 0.01, -0.05, 0.9],
    },
  },
  closed: {
    opacity: 0,
    transition: {
      duration: 0.6,
      ease: [0.6, 0.01, -0.05, 0.9],
      delay: 0.35,
      when: 'afterChildren',
    },
  },
}
const Modal = ({
  children,
  on,
  animKey,
  closeModal,
  modalPadding,
  useCloseButton,
  bgColor,
  useModalCard,
}) => {
  const [isModalOpen, setModalOpen] = useState(false)

  let modalChildren
  if (useModalCard === true) {
    modalChildren = (
      <ModalCard
        variants={modalChildVariants}
        initial="closed"
        animate="open"
        exit="closed"
        padding={modalPadding}
        key={animKey}
      >
        {children}
      </ModalCard>
    )
  } else if (useModalCard === false) {
    modalChildren = <>{children}</>
  }
  useEffect(() => {
    if (on) {
      setModalOpen(true)
    } else {
      setModalOpen(false)
    }
  }, [on, setModalOpen])
  useLockBodyScroll(isModalOpen)
  return (
    <AnimatePresence>
      {isModalOpen && (
        <ModalContainer
          variants={modalVariants}
          initial="closed"
          animate="open"
          exit="closed"
          key={animKey}
        >
          {modalChildren}
          {useCloseButton && <CloseModalBtn closeModal={closeModal} />}
          <Background bgColor={bgColor} />
        </ModalContainer>
      )}
    </AnimatePresence>
  )
}

export const CloseModalBtn = ({ closeModal }) => (
  <>
    <XButton
      whileHover={{ opacity: 1 }}
      onClick={closeModal}
      variants={closeVariants}
    >
      <motion.div />
      <motion.div />
      <motion.div />
    </XButton>
  </>
)

const XButton = styled(motion.button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: ${rhythm(1 / 1.333)};
  height: ${rhythm(1)};
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  top: 0;
  right: 0;
  margin: ${rhythm(1)};
  position: absolute;
  z-index: 10000000000;
  &:focus {
    outline: none;
  }
  div {
    width: ${rhythm(1)};
    height: ${rhythm(1 / 8)};
    background: white;
    border-radius: 10px;
    transition: background 0.5s ease-in;
    position: relative;
    transform-origin: 1.33px;
    &:first-of-type {
      transform: rotateZ(45deg);
    }
    &:nth-of-type(2) {
      transform: translateX(0);
      opacity: 0;
    }
    &:last-of-type {
      transform: rotateZ(-45deg);
    }
  }
`
const ModalContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
`
const ModalCard = styled(motion.div)`
  width: 100%;
  padding: ${props => props.padding || 0};
  z-index: 1200;
  position: relative;
`
const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.95;

  z-index: 900;
  background-color: ${props => props.bgColor};
`
export default Modal
