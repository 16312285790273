import React from 'react'
import { Flex } from 'rebass'
import { motion } from 'framer-motion'
import { rhythm } from '@utils/typography'
import styled from 'styled-components'
import { below } from '@utils/utilities'

const Sidelist = ({ clientSite, tags, tools, timeline }) => {
  const clientSiteNoHtml = clientSite
    .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
    .split('/')[0]
  return (
    <>
      <SidelistTitle
        flexDirection={['row', 'row', 'row', 'row']}
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <motion.ul>
          <li>
            <h5>Client Site</h5>
            <div className="divider" />
          </li>
          <li>
            <a
              href={clientSite}
              title={`Visit ${clientSite}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {clientSiteNoHtml}
            </a>
          </li>
        </motion.ul>
        <motion.ul>
          <li>
            <h5>Tools</h5>
            <div className="divider" />
          </li>
          <ul className="sublist">
            {tools.map(tool => (
              <li key={tool}>{tool}</li>
            ))}
          </ul>
        </motion.ul>
        <motion.ul className="principle">
          <li>
            <h5>Principle</h5>
            <div className="divider" />
          </li>
          <ul className="sublist">
            {tags.map(tag => (
              <li key={tag}>{tag}</li>
            ))}
          </ul>
        </motion.ul>
        <motion.ul className="timeline">
          <li>
            <h5>Timeline</h5>
            <div className="divider" />
          </li>
          <li>{timeline}</li>
        </motion.ul>
        <TitleLine style={{ bottom: '4px', left: 0, right: 0 }} />
      </SidelistTitle>
    </>
  )
}

export default Sidelist

const SidelistTitle = styled(motion.custom(Flex))`
  color: ${props => (props.theme.isDark ? 'white' : props.theme.primary)};
  height: 100%;
  position: relative;
  margin-top: ${rhythm(1 / 2.5)};
  ul {
    flex-grow: 1;
    margin-bottom: ${rhythm(1 / 2.5)};
    margin-left: 0;
    padding-right: ${rhythm(1 / 2)};
    list-style-type: none;
    display: flex;
    flex-direction: column;
    .sublist {
      justify-content: stretch;
      flex-direction: row;
      width: 100%;
      display: flex;
      li {
        padding-right: ${rhythm(1 / 6)};
        margin-bottom: 0;
        &:after {
          content: '+';
          padding-left: ${rhythm(1 / 6)};
        }
        :last-of-type {
          padding-right: 0%;
          &:after {
            content: '';
            padding-left: 0;
          }
        }
      }
    }
    h5 {
      font-family: made_tommy_light;
      text-transform: uppercase;
      position: relative;
      margin-bottom: ${rhythm(0)};
      ${below.xs`
    font-size:${rhythm(0.4325)};
  `}
    }
    .divider {
      display: none;
      width: 100%;
      height: 1px;
      background: ${props => props.theme.secondary};
    }
  }
  ul.principle {
    ${below.xs`
      display:none;
    `}
  }
  ul.timeline {
    ${below.s`
    display:none;
    `}
  }
  li {
    color: ${props =>
      props.theme.isDark
        ? props.theme.brand.litegrey
        : props.theme.brand.midnightXTlite};
  }

  li,
  a {
    margin-bottom: ${rhythm(1 / 4)};
    font-size: ${rhythm(1 / 2.313)};
    font-family: made_tommy_light;

    &::last-of-type {
      margin-bottom: ${rhythm(1)};
    }
  }
  a {
    margin-bottom: ${rhythm(1)};
    color: ${props =>
      props.theme.isDark
        ? props.theme.secondary
        : props.theme.brand.midnightXTlite};
  }
`
const TitleLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${props => props.theme.secondary};
  position: absolute;
`
