import React, { useEffect, useLayoutEffect, useCallback, useState } from 'react'
import { useTheme } from '@context/context'
import { TransitionPortal } from 'gatsby-plugin-transition-link'
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks'
import Modal from './modal'
import VimeoReel from './vimeoreel'

const ReelModal = ({ children, location }) => {
  const state = useTransitionState()
  const [on, toggle] = useState(false)
  const theme = useTheme()
  const handleClick = e => {
    e.preventDefault()
    toggle(!on)
  }

  const handleKeyUp = useCallback(
    e => {
      e.preventDefault()
      const { keyCode } = e
      if (on) {
        if (keyCode === 27) {
          toggle(false)
        }
      }
    },
    [on]
  )

  useEffect(() => {
    window.addEventListener(`keyup`, handleKeyUp, false)
    return () => {
      window.removeEventListener(`keyup`, handleKeyUp, false)
    }
  }, [on, handleKeyUp])

  useLayoutEffect(() => {
    if (state.current.state.fromReelLink) {
      toggle(true)
    } else if (location.hash.includes('#reel')) {
      toggle(true)
    }
  }, [state, location.hash])

  return (
    <>
      <div onClick={handleClick} onKeyPress={handleKeyUp} role="presentation">
        {children}
      </div>
      <TransitionPortal level="top">
        <Modal
          on={on}
          closeModal={() => toggle(false)}
          useCloseButton
          useModalCard
          bgColor={theme.primary}
          animKey="modalreel"
        >
          <VimeoReel />
        </Modal>
      </TransitionPortal>
    </>
  )
}

export default ReelModal
