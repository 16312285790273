import React from 'react'
// eslint-disable-next-line
import styled from 'styled-components'

const VimeoReel = () => (
  <VimeoContainer>
    <ReelPlayer
      src="https://player.vimeo.com/video/323642003?title=1&byline=1&portrait=0&autoplay=1&loop=0&autopause=0"
      allow="fullscreen; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </VimeoContainer>
)

const VimeoContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 41.67%;
  height: auto;
  margin: 0 auto;
  width: 90vw;
`

const ReelPlayer = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`
export default VimeoReel
