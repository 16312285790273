import Img from 'gatsby-image'
import { chunk, sum } from 'lodash'
import React, { useState, useEffect } from 'react'
import { Box } from 'rebass/styled-components'
import { useInView } from 'react-intersection-observer'

const Gallery = ({
  images,
  gifs,
  useGifs,
  itemsPerRow: itemsPerRowByBreakpoints,
}) => {
  const [galleryItems, setGalleryItems] = useState([])

  let newImages

  if (useGifs) {
    const gifsWithAspects = gifs.map(gif => {
      return { ...gif, aspectRatio: 1.7778 }
    })
    newImages = [...images, ...gifsWithAspects]
  } else {
    newImages = [...images]
  }

  useEffect(() => {
    const temp = newImages.slice()

    // eslint-disable-next-line no-plusplus
    for (let i = temp.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[temp[i], temp[j]] = [temp[j], temp[i]]
    }
    setGalleryItems(temp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const aspectRatios = galleryItems.map(image => image.aspectRatio)

  const rowAspectRatioSumsByBreakpoints = itemsPerRowByBreakpoints.map(
    itemsPerRow =>
      chunk(aspectRatios, itemsPerRow).map(rowAspectRatios =>
        sum(rowAspectRatios)
      )
  )
  return (
    <div>
      {galleryItems.map((image, i) => {
        let galleryItem
        if (image.publicURL) {
          galleryItem = (
            <GifVideo
              alt={image.name}
              rowAspectRatioSumsByBreakpoints={rowAspectRatioSumsByBreakpoints}
              i={i}
              image={image}
              itemsPerRowByBreakpoints={itemsPerRowByBreakpoints}
              key={image.id}
              src={image.publicURL}
            />
          )
        } else {
          galleryItem = (
            <Box
              as={Img}
              alt={image.originalName}
              title={image.originalName}
              key={image.src}
              fluid={image}
              width={rowAspectRatioSumsByBreakpoints.map(
                (rowAspectRatioSums, j) => {
                  const rowIndex = Math.floor(i / itemsPerRowByBreakpoints[j])
                  const rowAspectRatioSum = rowAspectRatioSums[rowIndex]

                  return `${(image.aspectRatio / rowAspectRatioSum) * 100}%`
                }
              )}
              css={{ display: 'inline-block' }}
            />
          )
        }

        return galleryItem
      })}
    </div>
  )
}
export const GifVideo = ({
  threshold = 0.15,
  image,
  alt,
  i,
  src,
  rowAspectRatioSumsByBreakpoints,
  itemsPerRowByBreakpoints,
  width,
}) => {
  const [ref, inView] = useInView({ threshold })

  useEffect(() => {
    if (inView) {
      ref.current?.play()
    } else {
      ref.current?.pause()
    }
  }, [ref, inView])

  return (
    <Box
      as="video"
      css={{ display: 'inline-block' }}
      ref={ref}
      autoPlay
      playsInline
      muted
      loop
      alt={alt}
      type="video/mp4"
      width={
        width ||
        rowAspectRatioSumsByBreakpoints.map(
          // Return a value for each breakpoint
          (rowAspectRatioSums, j) => {
            // Find out which row the image is in and get its aspect ratio sum
            const rowIndex = Math.floor(i / itemsPerRowByBreakpoints[j])
            const rowAspectRatioSum = rowAspectRatioSums[rowIndex]

            return `${(image.aspectRatio / rowAspectRatioSum) * 100}%`
          }
        )
      }
      src={src}
    />
  )
}

export default Gallery
