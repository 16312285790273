import { chunk, sum } from 'lodash'
import React, { useState, useEffect } from 'react'

import { GifVideo } from './gallery'

const GifGallery = ({ gifs, itemsPerRow: itemsPerRowByBreakpoints }) => {
  const [galleryItems, setGalleryItems] = useState([])

  const gifsWithAspects = gifs.map(gif => {
    return { ...gif, aspectRatio: 1.7778 }
  })

  const newImages = [...gifsWithAspects]

  useEffect(() => {
    const temp = newImages.slice()

    // eslint-disable-next-line no-plusplus
    for (let i = temp.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[temp[i], temp[j]] = [temp[j], temp[i]]
    }
    setGalleryItems(temp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const aspectRatios = galleryItems.map(image => image.aspectRatio)

  const rowAspectRatioSumsByBreakpoints = itemsPerRowByBreakpoints.map(
    itemsPerRow =>
      chunk(aspectRatios, itemsPerRow).map(rowAspectRatios =>
        sum(rowAspectRatios)
      )
  )

  return (
    <div>
      {galleryItems.map((image, i) => {
        return (
          <GifVideo
            alt={image.name}
            rowAspectRatioSumsByBreakpoints={rowAspectRatioSumsByBreakpoints}
            i={i}
            image={image}
            itemsPerRowByBreakpoints={itemsPerRowByBreakpoints}
            key={image.id}
            src={image.publicURL}
          />
        )
      })}
    </div>
  )
}

export default GifGallery
