import React from 'react'
import { graphql } from 'gatsby'
import { ProjectH1, ProjectH3 } from '@components/home/projectheading'
import SEO from '@components/seo'
import Video, { InlineVideo } from '@components/video'
import Sidelist from '@components/drilldown/sidelist'
import styled from 'styled-components'
import { rhythm } from '@utils/typography'
import { Flex, Box } from 'rebass'
import Gallery, { GifVideo } from '@components/drilldown/gallery'
import GifGallery from '@components/drilldown/gifgallery'
import TransitionContext from '@context/transitioncontext'
import { motion } from 'framer-motion'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'

const VideoCaption = styled.span`
  font-family: made_tommy_light;
  color: ${props => (props.theme.isDark ? 'white' : props.theme.secondary)};
  letter-spacing: 0;
  margin-top: 0;
  padding-top: 0;
  text-transform: none;
  font-style: italic;
  font-size: 14px;
`

const shortcodes = {
  Gallery,
  GifGallery,
  GifVideo,
  InlineVideo,
  VideoCaption,
  ProjectH1,
  ProjectH3,
}

const ProjectTemplate = ({ data: { mdx }, children, location }) => {
  const images = mdx.frontmatter.gallery
  const { gifs } = mdx.frontmatter
  const socialImage = mdx.frontmatter.socialCardImage.childImageSharp.resize

  return (
    <TransitionContext location={location}>
      <SEO
        title={`${mdx.frontmatter.client} - ${mdx.frontmatter.title}`}
        description={`${mdx.excerpt}`}
        image={socialImage}
      />
      <div className="projectbreakdown">
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <Video
            videoSrcURL={mdx.frontmatter.videoSrcUrl}
            widescreen={mdx.frontmatter.widescreen}
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          <Flex
            align="center"
            flexDirection="column"
            m="0 auto"
            py={[
              `${rhythm(1.2)}`,
              `${rhythm(1.85)}`,
              `${rhythm(2.5)}`,
              `${rhythm(2.5)}`,
            ]}
            px={[
              ` ${rhythm(1.2)}`,
              ` ${rhythm(2.1)}`,
              ` ${rhythm(3)}`,
              ` ${rhythm(3)}`,
            ]}
            maxWidth="1000px"
          >
            <ProjectH1>{mdx.frontmatter.client}</ProjectH1>

            <ProjectH3 noResize as="h3">
              {mdx.frontmatter.title}
            </ProjectH3>

            <Box>
              <Sidelist
                tags={mdx.frontmatter.tags}
                clientSite={mdx.frontmatter.clientSite}
                tools={mdx.frontmatter.tools}
                timeline={mdx.frontmatter.timeline}
              />
            </Box>
            <Flex
              pt={4}
              pb={4}
              sx={{ minHeight: '350px' }}
              flexDirection={['column', 'column', 'column', 'column']}
              alignItems="stretch"
              justifyContent="center"
            >
              <Box width={1} sx={{ position: 'relative' }}>
                <MDXProvider components={shortcodes}>
                  <MDXRenderer
                    frontmatter={mdx.frontmatter}
                    gifs={gifs}
                    images={images.map(image => ({
                      ...image.childImageSharp.fluid,
                    }))}
                  >
                    {mdx.body}
                  </MDXRenderer>
                </MDXProvider>
              </Box>
            </Flex>

            {/* <Gallery
              itemsPerRow={[1, 2, 3, 3]} // This will be changed to `[2, 3]` later
              images={images.map(image => ({
                ...image.childImageSharp.fluid,
              }))}
              gifs={gifs}
            /> */}
          </Flex>
        </motion.div>
      </div>
    </TransitionContext>
  )
}

export default ProjectTemplate

export const query = graphql`
  query ProjectQuery($id: String) {
    mdx(id: { eq: $id }) {
      body

      excerpt
      fields {
        slug
      }
      frontmatter {
        client
        title
        date
        clientSite
        socialCardImage: featureimage {
          childImageSharp {
            resize(width: 1024, height: 1024, cropFocus: CENTER) {
              src
              width
              height
            }
          }
        }
        featureimage {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        gifs {
          id
          publicURL
          name
        }
        gallery {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              aspectRatio
              originalName
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        projectType
        videoSrcUrl
        widescreen
        tags
        timeline
        tools
      }
    }
  }
`
