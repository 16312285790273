/* eslint-disable import/prefer-default-export */
import React from 'react'
import { Button } from '@elements/buttons'
import { useAppContext, useTheme } from '@context/context'
import ReelModal from '@components/modal/reelmodal'
import styled from 'styled-components'
import { rhythm } from '@utils/typography'
import { above, below } from '@utils/utilities'

const ProjectHeading = ({ project, dataAnchor, mb, location }) => {
  const { node } = project
  const theme = useTheme()
  const { useViewSwitch, setUseViewSwitch } = useAppContext()
  let heading
  if (node.frontmatter.title === '2020 Reel') {
    heading = (
      <>
        <HeroH1 theme={theme} className="firstName">
          ANDREW
        </HeroH1>
        <HeroH1 theme={theme} className="lastName">
          HOAG
        </HeroH1>

        <HeroH6>Motion | Animation | 3D</HeroH6>
        <ReelModal location={location}>
          <Button
            useButton
            buttonText="2020 REEL"
            padding={`0 ${rhythm(1)} `}
            height={`${rhythm(1.616)}`}
            maxWidth="200px"
          />
        </ReelModal>
      </>
    )
  } else {
    heading = (
      <>
        <ProjectH1 mb={mb} theme={theme}>
          {node.frontmatter.client}
        </ProjectH1>
        <ProjectH3>{node.frontmatter.title}</ProjectH3>
        <ProjectH4>
          {node.frontmatter.tags[0]} <span>|</span> {node.frontmatter.tags[1]}
        </ProjectH4>
        <Button
          isLink
          linkTo={node.fields.slug}
          buttonText="VIEW MORE"
          onClick={() => {
            if (useViewSwitch) {
              setUseViewSwitch(false)
            }
          }}
          padding={`0 ${rhythm(1)} `}
          height={`${rhythm(1.616)}`}
          maxWidth="200px"
          appearAfter="1"
          dataAnchor={dataAnchor}
        />
      </>
    )
  }
  return heading
}

export default ProjectHeading

export const HeroH1 = styled.h1`
  white-space: pre-line;
  overflow-wrap: break-word;
  position: relative;
  z-index: 1;
  margin-bottom: ${rhythm(1 / 4)};
  &:after {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    font: inherit;
    transform: translate3d(-4px, 4px, 0);
    opacity: 0.66;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #ffffff;
  }
  ${below.xs`
  font-size:44px;
margin-bottom:0;
  `}
`

export const HeroH6 = styled.h2`
  font-family: made_tommy_bold;
  font-weight: bold;
  text-transform: uppercase;
  font-size: ${rhythm(1 / 2.2)};
  letter-spacing: 1.5px;
  ${below.xs`
  font-size:8px;
  margin-top:${rhythm(1 / 2)};
  `}
`

export const ProjectH1 = styled.h1`
  white-space: pre-line;
  position: relative;
  z-index: 1;
  margin-bottom: ${props => props.mb || `${rhythm(1 / 4)}`};
  font-family: made_tommy_black;
  text-transform: uppercase;

  ${above.m`
max-width:700px;
`}

  ${below.xs`
    font-size:${rhythm(1.5)};
  `}
  span {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    font: inherit;
    transform: translate3d(-4px, 4px, 0);
    opacity: 0.66;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #ffffff;
  }
`
export const ProjectH3 = styled.h2`
  font-size: ${props => !props.noResize && rhythm(1 / 1.5)};
  font-family: ${props => !props.inline && 'made_tommy_medium'};
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;
  margin-bottom: ${rhythm(1 / 2.5)};
  color: ${props => props.inline && props.theme.secondary};
  text-align: ${props => props.textAlign};
  ${below.xs`
    font-size:${rhythm(0.425)};
  `}
`

export const ProjectH4 = styled.h3`
  font-family: made_tommy_thin;
  font-size: ${rhythm(0.4)};
  ${below.xs`
    font-size:${rhythm(0.35)};
  `}
`
