import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'

const Video = ({
  videoSrcURL,
  paddingBottom,
  position,
  videoTitle,
  isImage,
  trigger,
  widescreen,
}) => {
  return (
    <VideoWrapper layout className="video" widescreen={widescreen}>
      <iframe
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
      />
    </VideoWrapper>
  )
}
export default Video

export const VideoWrapper = styled(motion.div)`
  position: relative;
  padding-bottom: ${props => (props.widescreen ? '56.25%' : '42.85%')};
  iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
  }
`
export const InlineVideo = ({ videoSrcURL, videoTitle }) => {
  return (
    <video
      title={videoTitle}
      width="100%"
      height="auto"
      preload="auto"
      autoPlay
      muted
      playsInline
      loop
    >
      <source src={videoSrcURL} type="video/mp4" />
    </video>
  )
}
